<template>
  <div
    :class="
      cn(
        'bg-background text-card-foreground rounded-lg shadow-sm backdrop-blur-md',
        props.class
      )
    "
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  class?: ClassProp;
}>();
</script>
